import { useEffect, useRef, useState } from 'react';
import { API, OrgDateTime } from '../../../Root';
import { DateTime, Info } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import Immutable, { List, Map } from 'immutable';
import { setLayers, triggerSavingLayers } from '../store/skyview_s'
import { convertDatesInLayers } from "../../../shared/dates/convertDatesInLayers";



export function useLayersUpdateHook(props){

    const [isLoading, setIsLoading] = useState(false);
    const isMountingRef = useRef(false);
    const dispatch = useDispatch();


    const mapStyle = useSelector(state => {
        return state.skyview.get('mapStyle', 'dark-v1-')
    });
    const layers = useSelector(state => {
        let layers = state.skyview.get('layers', List([]))
        // layers = layers.map(layer => layer.set('isMapDataLoading', false));
        return layers;
    });
    const layersHaveUpdated = useSelector(state => {
        return state.skyview.get('layersHaveUpdated', false)
    });

    useEffect(() => {

        const fetchAllLayers = async ()=> {
            const apiResponse = await API.call({
                endpoint: API.endpoints['getAllLayers'],
                method: 'get',

            });
            if (!apiResponse.error){
                let layers = apiResponse;
                let mapStyle = layers.getIn([0, 'mapStyle'], 'dark-v10');
                layers = layers.deleteIn([0, 'mapStyle']);
                if( layers.size > 0 && layers.get(0).size === 0 ){
                    layers = layers.delete(0);
                }
                const _layers = convertDatesInLayers(layers);
                dispatch(setLayers(_layers, mapStyle));
            } else {
                dispatch(setLayers(List([]), 'dark-v10'));
            }
        };

        fetchAllLayers();

    }, []);

    useEffect(() => {
        if (isLoading || !layersHaveUpdated) return;
        if (layersHaveUpdated && !isLoading){
            dispatch(triggerSavingLayers());
            // adding mapStyle to the first layer
            const _layers = layers.setIn([0, 'mapStyle'], mapStyle);
            
            const saveLayerUpdate = async ()=> {
                const apiResponse = await API.subscribe({
                    endpoint: API.endpoints['updateLayers'],
                    method: 'post',
                    options: {
                        data: _layers,

                    }

                }, triggerSavingLayers);
                setIsLoading(false);
            };

            saveLayerUpdate();
            //
        };
    }, [layers, layersHaveUpdated, isLoading, mapStyle]);

    return isLoading;
};
